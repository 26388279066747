<template>
  <!-- 스포츠 종목선택-->
  <div class="sports_head">
    <div class="menu_list2">
      <ul class="list02">
        <li>
          <button class="button_type01" :class="{'type_active':gameType===0}"  @click="gameTypeChange(0)">전체</button>
        </li>
        <li>
          <button class="button_type01 " :class="{'type_active':gameType===49}" @click="gameTypeChange(49)"><img src="../../assets/images/left/icon/football-ico.png" alt="축구"></button>
        </li>
        <li>
          <button class="button_type01 " :class="{'type_active':gameType===50}" @click="gameTypeChange(50)"><img src="../../assets/images/left/icon/baseball-ico.png" alt="야구"></button>
        </li>
        <li>
          <button class="button_type01 " :class="{'type_active':gameType===51}" @click="gameTypeChange(51)"><img src="../../assets/images/left/icon/basketball-ico.png" alt="농구"></button>
        </li>
        <li>
          <button class="button_type01 " :class="{'type_active':gameType===52}" @click="gameTypeChange(52)"><img src="../../assets/images/left/icon/volleyball-ico.png" alt="배구"></button>
        </li>
        <li>
          <button class="button_type01 " :class="{'type_active':gameType===53}" @click="gameTypeChange(53)"><img src="../../assets/images/left/icon/hockey-ico.png" alt="아이스하키"></button>
        </li>
        <li>
          <button class="button_type01 " :class="{'type_active':gameType===56}" @click="gameTypeChange(56)"><img src="../../assets/images/left/icon/esport-ico.png" alt="Esports"></button>
        </li>
      </ul>
    </div>
  </div><!--sports_head end-->
</template>

<script>
  import sportsConst from "../../common/sportsConst";
  export default {
    name: "GameTypeButtonsComp",
    data(){
      return {
        gameType :0,
        sportsConst
      }
    },
    methods:{
      gameTypeChange(type){

        this.gameType = type
        // SportsNormalComp.vue 에서 $on
        this.$bus.$emit('gameTypeChange', type)
      }
    }
  }
</script>

<style scoped>
.type_active{
  color: #ffffff;
  font-weight: bold;
  background-color: #f19f09;
}
</style>